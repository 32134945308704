<template>
  <app-modal-copy-business
    v-if="
      business.business_status_id == BUSINESS_STATUS.CREATED ||
      business.business_status_id == BUSINESS_STATUS.AMENDED
    "
    :show="showModalCopyBusiness"
    @copy-business="copyBusiness"
    @show="showModalCopyBusiness = $event"
    show_info="package"
  />
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.package.title") }}
      </h1>
      <app-icon
        v-if="showCopyBusinessIcon"
        name="ClipboardCopyIcon"
        class="w-6 text-gray-400 cursor-pointer"
        @click="showModalCopyBusiness = true"
      />
    </div>
    <p class="mt-4">
      {{ $t("business.package.description") }}
      <a
        href="https://swipego.io/pricing-and-fees"
        class="text-bluelink text-base"
        target="_blank"
      >
        {{ $t("business.package.description_link") }}
      </a>
    </p>
  </div>
  <div class="w-full">
    <form @submit.prevent="submitBusinessPackage">
      <app-form-select
        class="lg:w-7/12 w-full mb-5"
        v-model="business.package"
        :datas="packages"
        :labelText="$t('business.package.title')"
        :capitalize="true"
        :loading="loading"
        :errorMessage="errors.package"
        :disabled="!editMode"
      />
      <app-form-input
        id="business.referral_code"
        name="business.referral_code"
        type="text"
        class="lg:w-7/12 w-full mb-5"
        v-model="this.referral_code"
        :labelText="$t('business.package.referral_code')"
        :errorMessage="errors.referral_code"
        :disabled="!editMode"
      />
      <app-form-input
        id="business.internal_voucher"
        name="business.internal_voucher"
        class="lg:w-7/12 w-full mb-5"
        type="text"
        :labelText="$t('business.package.promo_code')"
        v-model="this.internal_voucher"
        :errorMessage="errors.internal_voucher"
        :disabled="!editMode"
      />
      <app-button
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="lg:w-7/12 w-full"
      >
        {{ $t("general.next") }}
      </app-button>
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      showModalCopyBusiness: false,
      emptyErrors: {
        package: [],
        referral_code: [],
        internal_voucher: [],
      },
    };
  },
  async mounted() {
    this.getPackages();
    this.referral_code = this.business?.referral_code?.referral_code;
    this.internal_voucher = this.business?.internal_voucher?.name;
  },
  computed: {
    packages() {
      return this.$store.getters["referenceStore/packages"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    showCopyBusinessIcon() {
      let statusBusinessAllow = [
        BUSINESS_STATUS.CREATED,
        BUSINESS_STATUS.AMENDED,
      ];

      return (
        this.all_business?.length > 0 &&
        statusBusinessAllow.includes(this.business.business_status_id)
      );
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED ||
        this.business.business_status_id == null
      );
    },
  },
  methods: {
    async getPackages() {
      this.$store.commit("referenceStore/resetState");
      this.$store.dispatch("referenceStore/getPackages");
    },
    async submitBusinessPackage() {
      this.resetState();

      const businessPackage = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessPackage",
        {
          business_id: this.business.id,
          package: this.business?.package?.id ?? null,
          referral_code: this.referral_code ?? null,
          internal_voucher: this.internal_voucher ?? null,
        }
      );

      if (businessPackage == null) {
        return;
      }

      this.$router.push({ path: "/business/summary" });
    },
    copyBusiness(business) {
      this.business.package = business.package;
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
